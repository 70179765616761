import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import React, { useEffect, useState } from 'react';
import PaymentService from '../services/PaymentService';
import '../BuyScreen.css';
import UserService from '../services/UserService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faFileInvoice, faWallet } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import Popup from '../components/Popup';

export enum PaymentStatus {
  Unpaid = 'unpaid',
  Paid = 'paid',
  Failed = 'failed',
  Processing = 'processing',
  Pending = 'pending',
  Cancelled = 'cancelled',
}

const statusMap: Record<PaymentStatus, string> = {
  [PaymentStatus.Unpaid]: 'Non payé',
  [PaymentStatus.Paid]: 'Payé',
  [PaymentStatus.Processing]: 'En cours',
  [PaymentStatus.Failed]: 'Échoué',
  [PaymentStatus.Pending]: 'En attente',
  [PaymentStatus.Cancelled]: 'Annulé',
};

const statusClassMap: Record<PaymentStatus, string> = {
  [PaymentStatus.Unpaid]: 'status-pending',
  [PaymentStatus.Paid]: 'status-validate',
  [PaymentStatus.Processing]: 'status-pending',
  [PaymentStatus.Failed]: 'status-error',
  [PaymentStatus.Pending]: 'status-pending',
  [PaymentStatus.Cancelled]: 'status-error',
};

const BuyScreen = () => {
  const [payments, setPayments] = useState<any[]>([]);
  const [loadingPaymentId, setLoadingPaymentId] = useState<string | null>(null);
  const [paymentMethodsExist, setPaymentMethodsExist] = useState(false);
  const [token, setToken] = useState<string>("");
  const { getItem } = useAsyncStorage('token');
  const [hasDefaultPaymentMethod, setHasDefaultPaymentMethod] = useState(false);

  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');

  useEffect(() => {
    const getToken = async () => {
      try {
        const savedToken = await getItem();
        if (savedToken !== null) {
          setToken(savedToken);
        }
      } catch (error) {
        console.error('Error loading token from AsyncStorage:', error);
      }
    };
    getToken();
  }, []);

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        if (!token) return;
        const response = await PaymentService.getPayments(token);

        const sortedPayments = (response || []).sort((a: any, b: any) => {
          const priorityStatuses = [PaymentStatus.Unpaid, PaymentStatus.Pending];
          if (priorityStatuses.includes(a.status) && !priorityStatuses.includes(b.status)) {
            return -1;
          }
          if (!priorityStatuses.includes(a.status) && priorityStatuses.includes(b.status)) {
            return 1;
          }
          return 0;
        });

        setPayments(sortedPayments);

        const paymentMethodsResponse = await UserService.getPaymentMethods(token);
        const methods = paymentMethodsResponse?.paymentMethods || [];
        setPaymentMethodsExist(methods.length > 0);
        setHasDefaultPaymentMethod(paymentMethodsResponse.defaultPaymentMethodId != null);
      } catch (error) {
        console.error('Erreur lors du chargement des paiements', error);
      }
    };

    if (token) {
      fetchPayments();
    }
  }, [token]);

  const handlePayment = async (paymentId: string) => {
    try {
      setLoadingPaymentId(paymentId);

      const response = await PaymentService.payPayment(token, paymentId);

      if (response.success) {
        setPopupMessage(t("paiment_success"));
        setShowPopup(true);
        setPayments((prevPayments) =>
          prevPayments.map((payment) =>
            payment._id === paymentId ? { ...payment, status: PaymentStatus.Paid } : payment
          )
        );
      } else if (response.payment && response.payment.status === PaymentStatus.Processing) {
        setPopupMessage(t("paiment_processing"));
        setShowPopup(true);
        setPayments((prevPayments) =>
          prevPayments.map((payment) =>
            payment._id === paymentId ? { ...payment, status: PaymentStatus.Processing } : payment
          )
        );
      } else {
        setPopupMessage('Erreur lors du paiement.');
        setShowPopup(true);
      }
    } catch (error) {
      setPopupMessage('Une erreur est survenue lors du paiement.');
      setShowPopup(true);
      console.error(error);
    } finally {
      setLoadingPaymentId(null);
    }
  };

  return (
    <div className="buy-screen">
      <h2>{t("mes-paiements")}</h2>
      {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
      {payments.length === 0 ? (
        <p>{t("no-paiement-pour-le-moment")}</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>{t("nom-defi")} <FontAwesomeIcon icon={faChevronDown} /></th>
              <th>{t("montant-ht")} <FontAwesomeIcon icon={faChevronDown} /></th>
              <th>{t("montant-ttc")} <FontAwesomeIcon icon={faChevronDown} /></th>
              <th>{t("status")} <FontAwesomeIcon icon={faChevronDown} /></th>
              <th>{t("action")}</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment) => {
              const statusKey = payment.status as PaymentStatus;
              const statusClass = statusClassMap[statusKey];
              return (
                <>
                  <tr key={payment._id}>
                    <td>{payment.description}</td>
                    <td>{payment.value} €</td>
                    <td>{payment.totalAmountWithTax} €</td>
                    <td>
                      <p className={statusClass}>{statusMap[statusKey]}</p>
                    </td>
                    <td>
                      {(payment.status === PaymentStatus.Unpaid || payment.status === PaymentStatus.Pending) ? (
                        paymentMethodsExist ? (
                          hasDefaultPaymentMethod ? (
                            <button
                              onClick={() => handlePayment(payment._id)}
                              disabled={loadingPaymentId === payment._id}
                              className="action-button"
                            >
                              <FontAwesomeIcon icon={faWallet} />
                              {loadingPaymentId === payment._id ? t("paiement-en-cours") : "Valider"}
                            </button>
                          ) : (
                            <button className="action-button" disabled>{t("ajouter-un-paiement-par-default")}</button>
                          )
                        ) : (
                          <button className="action-button" disabled>{t("ajouter-un-moyen-depaiment")}</button>
                        )
                      ) : payment.status === PaymentStatus.Paid ? (
                        payment.invoiceUrl && (
                          <a href={payment.invoiceUrl} target="_blank" rel="noopener noreferrer">
                            <button className="action-button">
                              <FontAwesomeIcon icon={faFileInvoice} />
                              {t("telecharger-facture")}
                            </button>
                          </a>
                        )
                      ) : payment.status === PaymentStatus.Processing ? (
                        <span>{t("paiement-en-cours")}</span>
                      ) : (
                        <span>{t("status-inconnu")}</span>
                      )}
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default BuyScreen;

