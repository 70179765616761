import React from 'react';
import { ArrowUp, ArrowDown } from 'lucide-react';

interface StatItemProps {
  label: string;
  value: number | string | null | undefined; // Autoriser les valeurs null ou undefined
  unit?: string;
  evolution?: number;
}

export const StatItem: React.FC<StatItemProps> = ({ label, value, unit = '', evolution }) => {
  // Si value est null ou undefined, afficher une valeur par défaut
  const formattedValue = value !== null && value !== undefined ? value.toLocaleString('fr-FR') : 'N/A';

  return (
    <div className="flex items-center justify-between py-3 border-b border-[#E0CEFF] last:border-0">
      <span className="text-[#270D60] font-medium">{label}</span>
      <div className="flex items-center gap-3">
        <span className="font-semibold text-[#270D60]">
          {formattedValue} {unit}
        </span>
        {evolution !== undefined && (
          <div className={`flex items-center gap-1 ${evolution >= 0 ? 'text-green-600' : 'text-red-600'}`}>
            {evolution >= 0 ? <ArrowUp size={16} /> : <ArrowDown size={16} />}
            <span className="text-sm font-medium">{Math.abs(Math.round(evolution))}%</span>
          </div>
        )}
      </div>
    </div>
  );
};
