import React from 'react';
import { LucideIcon } from 'lucide-react';
import { Tooltip } from './Tooltip';

interface KPICardProps {
  title: string;
  value: string | number;
  evolution?: number;
  icon: LucideIcon;
  tooltip: string;
}

export default function KPICard({ title, value, evolution, icon: Icon, tooltip }: KPICardProps) {
  return (
    <div className="relative group h-40"> {/* Hauteur fixe définie ici */}
      <div className="bg-white rounded-xl p-6 border border-[#E0CEFF] transition-all hover:shadow-lg h-full flex flex-col justify-between">
        <div className="flex items-center justify-between">
          <div className="relative">
            <Icon size={24} className="text-[#270D60]" />
            <Tooltip text={tooltip} />
          </div>
          {evolution !== undefined && (
            <div
              className={`px-3 py-1.5 rounded-full text-sm flex items-center gap-1.5 ${
                evolution >= 0 ? 'bg-[#BCF4DE] text-[#0E4F2C]' : 'bg-[#F13C3C33] text-[#F13C3C]'
              }`}
              style={{ whiteSpace: 'nowrap' }} // Garde le texte sur une seule ligne
            >
              <span className="font-medium">
                {evolution >= 0 ? '+' : ''}{Math.round(evolution)}%
              </span>
            </div>
          )}
        </div>
        <div>
          <h3 className="text-4xl font-bold text-[#270D60] mb-2">{value}</h3>
          <p className="text-sm text-[#270D60] opacity-80">{title}</p>
        </div>
      </div>
    </div>
  );
}