import React from 'react';

interface TooltipProps {
  text: string;
}

export const Tooltip: React.FC<TooltipProps> = ({ text }) => (
  <div className="absolute z-50 invisible opacity-0 group-hover:visible left-[calc(50%+50px)] group-hover:opacity-100 transition-all duration-200 bottom-full left-1/2 -translate-x-1/2 mb-2 px-3 py-2 text-sm text-white bg-[#270D60] rounded-lg w-48 shadow-lg">
    {text}
    <div className="absolute left-1/2 bottom-[-6px] -translate-x-1/2 border-solid border-4 border-transparent border-t-[#270D60]" />
  </div>
);