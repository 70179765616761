import React from 'react';
import { ArrowUp, ArrowDown } from 'lucide-react';
import { StatItem } from './StatItem';

interface SportStatsProps {
  data: {
    totalSteps: number;
    avgStepsPerDay: number;
    stepsInKm: number;
    totalMinutes: number;
    minutesInKm: number;
    totalKm: number;
  };
  previousData?: {
    totalSteps: number;
    avgStepsPerDay: number;
    stepsInKm: number;
    totalMinutes: number;
    minutesInKm: number;
    totalKm: number;
  };
}

const SportStats: React.FC<SportStatsProps> = ({ data, previousData }) => {
  const getEvolution = (current: number, previous?: number) => {
    if (!previous) return undefined;
    return ((current - previous) / previous) * 100;
  };

  const convertStepsToKm = (steps: number) => {
    return (steps * 0.00078).toFixed(2); // Conversion en kilomètres avec 2 décimales
  };

  

  return (
    <div className="bg-white rounded-xl p-6 border border-[#E0CEFF] shadow-sm">
      <h3 className="text-xl font-semibold text-[#270D60] mb-6">Statistiques sportives</h3>
      <div className="space-y-4">
        <StatItem 
          label="Nombre de pas total"
          value={`${data.totalSteps} (environ ${convertStepsToKm(data.totalSteps)} km)`}
          evolution={getEvolution(data.totalSteps, previousData?.totalSteps)}
        />
        <StatItem 
          label="Moyenne de pas par jour"
          value={data.avgStepsPerDay}
          evolution={getEvolution(data.avgStepsPerDay, previousData?.avgStepsPerDay)}
        />
        <StatItem 
          label="Distance totale des activités sportives"
          value={data.totalKm}
          unit="km"
          evolution={getEvolution(data.totalKm, previousData?.totalKm)}
        />
        <StatItem 
          label="Temps d'activité"
          value={data.totalMinutes}
          unit="min"
          evolution={getEvolution(data.totalMinutes, previousData?.totalMinutes)}
        />
      </div>
    </div>
  );
};

export default SportStats;