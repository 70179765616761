import React from 'react';
import { CalendarIcon } from 'lucide-react';

interface DateRangePickerProps {
  startDate: Date;
  endDate: Date;
  onStartDateChange: (date: Date) => void;
  onEndDateChange: (date: Date) => void;
  label: string;
  disabled?: boolean;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  label,
  disabled = false
}) => {
  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = e.target.value ? new Date(e.target.value) : startDate;
    onStartDateChange(newDate);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = e.target.value ? new Date(e.target.value) : endDate;
    onEndDateChange(newDate);
  };

  return (
    <div className="bg-white rounded-xl p-6 border border-[#E0CEFF]">
      <h3 className="text-lg font-medium text-[#270D60] mb-4">{label}</h3>
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="flex-1">
          <label className="block text-sm text-[#270D60] mb-2">Date de début</label>
          <div className="relative">
            <input
              type="date"
              value={startDate.toISOString().split('T')[0]}
              onChange={handleStartDateChange}
              className="w-full px-4 py-2 border border-[#E0CEFF] rounded-lg pr-10"
              disabled={disabled}
            />
            <CalendarIcon className="absolute right-3 top-1/2 -translate-y-1/2 text-[#270D60] w-5 h-5" />
          </div>
        </div>
        <div className="flex-1">
          <label className="block text-sm text-[#270D60] mb-2">Date de fin</label>
          <div className="relative">
            <input
              type="date"
              value={endDate.toISOString().split('T')[0]}
              onChange={handleEndDateChange}
              className="w-full px-4 py-2 border border-[#E0CEFF] rounded-lg pr-10"
              disabled={disabled}
              min={startDate.toISOString().split('T')[0]}
            />
            <CalendarIcon className="absolute right-3 top-1/2 -translate-y-1/2 text-[#270D60] w-5 h-5" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateRangePicker;
