import React, { useState } from 'react';

import Header from '../components/Header';
import { useTranslation } from 'react-i18next';
import PeriodComparison from '../components/kpi/PeriodComparison';


const HomeScreen = () => {

    const [activeTab, setActiveTab] = useState<'global' | 'weekly'>('global');
  
    const minDate = new Date(2024, 0, 1); // 1er janvier 2024
    const maxDate = new Date(2025, 11, 4);  // 4 février 2024
  
    const {t} = useTranslation();

    return (
        <div className='container'>
                      <Header title={t("tableau-de-bord")} />

                      <div className="min-h-screen bg-[#F5F1FF] p-8">
      <h1 className="text-2xl font-bold text-[#270D60] mb-8">Tableau de bord : KPI des Challenges</h1>
      <PeriodComparison minDate={minDate} maxDate={maxDate} />
    </div>

        </div>

    );
};

export default HomeScreen;
