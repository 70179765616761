import moment from 'moment-timezone';
import axios from "axios";
let BASE_URL: any;


if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  BASE_URL = process.env.REACT_APP_API_URL_DEV;
} else {
  BASE_URL = process.env.REACT_APP_API_URL_PROD;
}

export const StatsService = {
  async fetchStats(token: string, startDate: Date, endDate: Date, selectedAgendaId: string) {
    const convertToStartOfFrenchDayUTC = (date: Date) => {
      return moment(date)
        .tz('Europe/Paris') // Considérer la date en heure française
        .startOf('day') // Début de journée en France (minuit)
        .utc() // Convertir en UTC
        .toISOString(); // Retourner en format ISO UTC
    };
    const adjustedStartDate = convertToStartOfFrenchDayUTC(startDate);
    const adjustedEndDate = convertToStartOfFrenchDayUTC(endDate);

    try {
      const response = await axios.get(`${BASE_URL}/api/kpi/fetchStats/${adjustedStartDate}/${adjustedEndDate}/${startDate}/${endDate}/${selectedAgendaId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }


    /*      console.log('Fetching stats with token:', token, 'from:', startDate, 'to:', endDate);
     
         // Simuler un délai d'appel serveur
         await new Promise((resolve) => setTimeout(resolve, 500));
     
         // Calculer le nombre de jours dans la période pour ajuster les données
         const daysBetween = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
     
         // Fonction utilitaire pour générer un nombre aléatoire dans une plage
         const getRandom = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;
     
         // Exemple de données simulées avec des variations aléatoires
         const simulatedStats = {
           engagementRate: getRandom(15, 30), // Pourcentage d'engagement (15% à 30%)
           participationRate: getRandom(10, 25), // Pourcentage de participation (10% à 25%)
           completionRate: getRandom(80, 95), // Pourcentage de complétion (80% à 95%)
           inscriptions: getRandom(100, 150) + daysBetween * getRandom(1, 5), // Nombre d'inscriptions, ajusté par la période
           totalSteps: getRandom(5000, 10000) * daysBetween, // Nombre total de pas
           avgStepsPerDay: getRandom(3000, 7000), // Moyenne de pas par jour
           totalMinutes: getRandom(1200, 2000) + daysBetween * getRandom(10, 50), // Minutes totales d'activité
           minutesInKm: getRandom(200, 400), // Minutes converties en km
           totalKm: getRandom(50, 150) + daysBetween * getRandom(1, 3), // Kilomètres totaux parcourus
         };
     
         return simulatedStats; */
  },
};
