import React, { useState, useEffect } from 'react';
import KPICard from './KPICard';
import { Users, Target, Trophy, Activity } from 'lucide-react';
import DateRangePicker from './DateRangePicker';
import SportStats from './SportStats';
import { getDaysBetweenDates, addDays, formatDateRange } from '../../utils/dateUtils';
import { StatsService } from '../../services/StatsService';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import UserService from '../../services/UserService';
import { IUser } from '../../interfaces/IUsers';
import EntrepriseService from '../../services/EntrepriseService';

interface PeriodComparisonProps {
  minDate: Date;
  maxDate: Date;
}

const PeriodComparison: React.FC<PeriodComparisonProps> = ({ minDate, maxDate }) => {

  const [primaryStartDate, setPrimaryStartDate] = useState<Date>(new Date());
  const [primaryEndDate, setPrimaryEndDate] = useState<Date>(new Date());
  const [comparisonStartDate, setComparisonStartDate] = useState<Date | null>(null);
  const [comparisonEndDate, setComparisonEndDate] = useState<Date | null>(null);
  const [showComparison, setShowComparison] = useState<boolean>(false);
  const [primaryStats, setPrimaryStats] = useState<any | null>(null);
  const [comparisonStats, setComparisonStats] = useState<any | null>(null);
  const [loadingPrimary, setLoadingPrimary] = useState<boolean>(false);
  const [loadingComparison, setLoadingComparison] = useState<boolean>(false);
  const [datesUpdated, setDatesUpdated] = useState<boolean>(false);
  const [selectedAgendaId, setSelectedAgendaId] = useState<string | null>(null);
  const [agendas, setAgendas] = useState<any[]>([]);
  const [token, setToken] = useState('');
  const { getItem } = useAsyncStorage('token');
  const [user, setUser] = useState<IUser>();


  useEffect(() => {
    const getToken = async () => {
      try {
        const savedToken = await getItem();
        if (savedToken !== null) {
          setToken(savedToken);
          console.log('Token loaded :', savedToken);
        }
      } catch (error) {
        console.error('Error loading token from AsyncStorage:', error);
      }
    };

    getToken();
  }, []);


  useEffect(() => {
    if (!token) return;

    UserService.getUser(token).then((data: any) => {
      setUser(data);
      EntrepriseService.getCompanyAgendas(token, data?.companyId).then(agendas => {

        if (agendas.length > 0) {
          setAgendas(agendas);
          setSelectedAgendaId(agendas[0]._id);
        }

      })
    }).catch((error: any) => {
      console.error('Error fetching challenges:', error);
    });
  }, [token]);


  const loadPrimaryStats = async () => {
    setLoadingPrimary(true);
    console.log("load ageda", selectedAgendaId)
    try {
      if (!selectedAgendaId) return;
      const stats = await StatsService.fetchStats(token, primaryStartDate, primaryEndDate, selectedAgendaId);
      setPrimaryStats(stats);
    } catch (error) {
      console.error('Erreur lors du chargement des statistiques principales', error);
      setPrimaryStats(null);
    } finally {
      setLoadingPrimary(false);
    }
  };

  const loadComparisonStats = async () => {
    if (!comparisonStartDate || !comparisonEndDate) return;
    setLoadingComparison(true);
    try {
      if (!selectedAgendaId) return;
      const stats = await StatsService.fetchStats(token, comparisonStartDate, comparisonEndDate, selectedAgendaId);
      setComparisonStats(stats);
    } catch (error) {
      console.error('Erreur lors du chargement des statistiques de comparaison', error);
      setComparisonStats(null);
    } finally {
      setLoadingComparison(false);
    }
  };

  const handleValidate = () => {
    setDatesUpdated(false);
    loadPrimaryStats();
    if (showComparison) {
      loadComparisonStats();
    }
  };

  const handleToggleComparison = () => {
    setShowComparison(!showComparison);

    if (!showComparison) {
      // Définir des dates par défaut basées sur la période principale
      const daysDiff = getDaysBetweenDates(primaryStartDate, primaryEndDate) + 1;
      const newComparisonEndDate = addDays(primaryStartDate, -1);
      const newComparisonStartDate = addDays(newComparisonEndDate, -daysDiff);

      setComparisonStartDate(newComparisonStartDate);
      setComparisonEndDate(newComparisonEndDate);
    }
  };

  useEffect(() => {
    /*     if (showComparison) {
          const daysDiff = getDaysBetweenDates(primaryStartDate, primaryEndDate) + 1; // Corrige le décalage
          const newComparisonEndDate = addDays(primaryStartDate, -1);
          const newComparisonStartDate = addDays(newComparisonEndDate, -daysDiff);
    
          setComparisonStartDate(newComparisonStartDate);
          setComparisonEndDate(newComparisonEndDate);
        } */
    setDatesUpdated(true);
  }, [primaryStartDate, primaryEndDate, showComparison]);

  useEffect(() => {
    if (showComparison) {
      setDatesUpdated(true);
    }
  }, [comparisonStartDate, comparisonEndDate]);

  return (
    <div className="space-y-8 max-w-7xl mx-auto">
      <div className="flex flex-col gap-6">

        <div className="bg-white p-4 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold text-[#270D60] mb-4">Sélectionnez un challenge</h3>
          <div className="flex flex-wrap gap-4">
            {agendas.map((agenda) => (
              <button
                key={agenda.id}
                onClick={() => setSelectedAgendaId(agenda._id)}
                className={`px-4 py-2 rounded-lg ${selectedAgendaId === agenda._id
                  ? 'bg-[#270D60] text-white'
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                  }`}
              >
                {agenda.name}
              </button>
            ))}
          </div>
        </div>


        <DateRangePicker
          startDate={primaryStartDate}
          endDate={primaryEndDate}
          onStartDateChange={setPrimaryStartDate}
          onEndDateChange={setPrimaryEndDate}
          label="Période principale"
        />

        <div className="flex items-center gap-4">
          <button
            onClick={handleToggleComparison}
            className="px-4 py-2 bg-[#270D60] text-white rounded-lg hover:bg-[#1a0940] transition-colors"
          >
            {showComparison ? 'Masquer la comparaison' : 'Ajouter une comparaison'}
          </button>
          {datesUpdated && (
            <button
              onClick={handleValidate}
              className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-500 transition-colors"
            >
              Valider
            </button>
          )}
        </div>

        {showComparison && (
          <DateRangePicker
            startDate={comparisonStartDate || new Date()}
            endDate={comparisonEndDate || new Date()}
            onStartDateChange={setComparisonStartDate}
            onEndDateChange={setComparisonEndDate}
            label="Période de comparaison"
          />
        )}
      </div>

      <div className={`grid gap-8 ${showComparison ? 'grid-cols-1 lg:grid-cols-2' : 'grid-cols-1'}`}>
        {/* Statistiques de la période principale */}
        <div className="space-y-6">
          <h2 className="text-xl font-semibold text-[#270D60]">
            {formatDateRange(primaryStartDate, primaryEndDate)}
          </h2>
          <div className={`grid gap-4 grid-cols-1 md:grid-cols-2`}>
            {loadingPrimary ? (
              <div className="loader">Chargement des données...</div>
            ) : primaryStats ? (
              <>
                <KPICard
                  title="Taux d'engagement"
                  value={`${primaryStats.engagementRate}%`}
                  icon={Target}
                  tooltip="Pourcentage d'utilisateurs actifs qui participent régulièrement"
                />
                <KPICard
                  title="Connexion journalière par utilisateur"
                  value={`${primaryStats.averageConnectionsPerDay}`}
                  icon={Activity}
                  tooltip="Nombre de connexion par utilisateur moyen sur la période"
                />
                <KPICard
                  title="Taux de complétion"
                  value={`${primaryStats.completionRate}%`}
                  icon={Trophy}
                  tooltip="Pourcentage de challenges terminés"
                />
                <KPICard
                  title="Joueurs inscrits"
                  value={primaryStats.inscriptions}
                  icon={Users}
                  tooltip="Nombre total d'inscriptions"
                />
              </>
            ) : (
              <div>Aucune donnée disponible</div>
            )}
          </div>
          {!loadingPrimary && primaryStats && (
            <SportStats
              data={{
                totalSteps: primaryStats.totalSteps,
                avgStepsPerDay: primaryStats.avgStepsPerDay,
                stepsInKm: Math.round(primaryStats.totalSteps * 0.0007),
                totalMinutes: primaryStats.totalMinutes,
                minutesInKm: primaryStats.minutesInKm,
                totalKm: primaryStats.totalKm,
              }}
            />
          )}
        </div>

        {/* Statistiques de la période de comparaison */}
        {showComparison && (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-[#270D60]">
              {comparisonStartDate && comparisonEndDate
                ? formatDateRange(comparisonStartDate, comparisonEndDate)
                : 'Période de comparaison'}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {loadingComparison ? (
                <div className="loader">Chargement des données...</div>
              ) : comparisonStats ? (
                <>
                  <KPICard
                    title="Taux d'engagement"
                    value={`${comparisonStats.engagementRate}%`}
                    evolution={((comparisonStats.engagementRate - primaryStats.engagementRate) / primaryStats.engagementRate) * 100}
                    icon={Target}
                    tooltip="Pourcentage d'utilisateurs qui ont participé à au moins un challenge"
                  />
                  <KPICard
                    title="Connexion journalière par utilisateur"
                    value={`${comparisonStats.averageConnectionsPerDay}`}
                    icon={Activity}
                    tooltip="Nombre de connexion par utilisateur moyen sur la période"
                  />
                  <KPICard
                    title="Taux de complétion"
                    value={`${comparisonStats.completionRate}%`}
                    evolution={((comparisonStats.completionRate - primaryStats.completionRate) / primaryStats.completionRate) * 100}
                    icon={Trophy}
                    tooltip="Pourcentage de challenges terminés"
                  />
                  <KPICard
                    title="Joueurs inscrits"
                    value={comparisonStats.inscriptions}
                    evolution={((comparisonStats.inscriptions - primaryStats.inscriptions) / primaryStats.inscriptions) * 100}
                    icon={Users}
                    tooltip="Nombre total d'inscriptions"
                  />
                </>

              ) : (
                <div>Aucune donnée disponible</div>
              )}
            </div>
            {!loadingComparison && comparisonStats && (
              <SportStats
                data={{
                  totalSteps: comparisonStats.totalSteps,
                  avgStepsPerDay: comparisonStats.avgStepsPerDay,
                  stepsInKm: Math.round(comparisonStats.totalSteps * 0.0007),
                  totalMinutes: comparisonStats.totalMinutes,
                  minutesInKm: comparisonStats.minutesInKm,
                  totalKm: comparisonStats.totalKm,
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PeriodComparison;
